export default (options = {}) => ({
  $container: null,
  $flkty: null,
  loaded: false,
  isTight: false,
  count: 0,
  total: 0,
  index: 0,
  captionsHtml: '',
  options: {},
  defaultOptions: {
    imagesLoaded: true,
    selectedAttraction: 0.15,
    friction: 0.6,
    wrapAround: true,
    lazyLoad: 3,
    arrowShape: '',
    draggable: false
  },

  init() {
    this.setOptions(options)
    this.$container = this.$refs.container || this.$el
    if (this.$refs.captions) Array.from(this.$container.querySelectorAll('figcaption')).map((element) => element.parentNode?.classList.add('hidden'))
   

    this.$nextTick(() => {
      this.$container.classList.add('loaded')

      this.$flkty = new Flickity(this.$container, this.options)
      this.totalSlides = this.$flkty.slides.length

      this.$flkty.cells.map((cell, index) => cell.element.setAttribute('data-index', index))

      this.$flkty.on('change', (i) => {
        this.update(i)
      })

      this.update().then(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.loaded = true
            this.setCellHeight()
            this.$flkty.resize()
          }, 0)
        })
      })
    })
  },
  setOptions(options) {
    this.options = { ...this.defaultOptions, ...options }
  },
  update(i = 0) {
    return new Promise((resolve, reject) => {
      this.index = i
      this.count = this.getCount()
      this.total = this.getTotal()
      const $slide = this.$flkty.slides[i]
      if (this.$refs.captions) {
        this.captionsHtml = $slide.cells
          .map((cell) => {
            let figcaption = cell.element.querySelector('figcaption')?.cloneNode(true)
            if (figcaption) {
              //figcaption.removeAttribute('class')
              //figcaption.classList.add('mt-2.5')
              figcaption.classList.remove('sr-only')
              return figcaption.outerHTML
            } else {
              return ''
            }
          })
          .reduce((html, reducer) => html + reducer)
      }
      resolve()
    })
  },
  prev() {
    this.$flkty.previous()
  },

  next() {
    this.$flkty.next()
  },

  getCount() {
    // let currentIndex = parseInt(this.$flkty.selectedElement.dataset.index)
    // let cellElements = this.$flkty.selectedElements.length
    // return cellElements > 1 ? `${currentIndex + 1} – ${currentIndex + cellElements}` : currentIndex + 1
    return this.$flkty.selectedIndex + 1
  },

  getTotal() {
    // this.total = this.$flkty.cells.length
    return this.$flkty.slides.length
  },

  isFirst() {
    return this.index === 0
  },

  isLast() {
    return this.index === this.totalSlides - 1
  },

  getContentWidth() {
    return this.$getContentWidth(this.$container)
  },

  cellHeight: 0,
  setCellHeight() {
    const buttonsHeight = this.$refs?.buttons ? this.$refs.buttons.offsetHeight : 0
    const captionsHeight = this.$refs?.captionsWrapper ? this.$refs.captionsWrapper.offsetHeight : 0
    this.cellHeight = this.$el.offsetHeight - buttonsHeight - captionsHeight
  },
  onImageLoad() {
    if (this.loaded) this.$flkty.resize()
  }
})
