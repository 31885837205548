export default () => ({
  items: [],
  get filteredItems() {
    return this.items.filter((item) => {
      let selected = true
     
      if (this.filter_category.length && !this.filter_category.every((category) => item.categoriesIds.includes(String(category)))) selected = false
      if (this.filter_tag.length && !this.filter_tag.every((tag) => item.tagsIds.includes(String(tag)))) selected = false

      return selected

    })
  },
  
  get filter_tag() {
    return this.$store.url.getParam('tag') ? (Array.isArray(this.$store.url.getParam('tag')) ? this.$store.url.getParam('tag') : [this.$store.url.getParam('tag')]) : []
  },

  get filter_category() {
    return this.$store.url.getParam('category') ? (Array.isArray(this.$store.url.getParam('category')) ? this.$store.url.getParam('category') : [this.$store.url.getParam('category')]) : []
  },
  setItems(items = []) {
    this.items = [...items]
  }
})
