export default (options) => ({
  iframe: null,
  init(options) {
    this.iframe = this.$el.querySelector('iframe')
    if (this.iframe) {
      let width = this.iframe.offsetWidth
      let height = this.iframe.offsetHeight

      this.iframe.style.position = 'absolute'
      this.iframe.style.top = 0
      this.iframe.style.bottom = 0
      this.iframe.style.left = 0
      this.iframe.style.right = 0
      this.iframe.style.margin = 0
      this.iframe.style.width = '100%'
      this.iframe.style.height = '100%'

      this.$el.style.paddingTop = (height / width) * 100 + '%'
      this.$el.style.position = 'relative'
    }
  }
})
