document.addEventListener('DOMContentLoaded', (event) => {
  console.log(`Théâtre - 2023\nSilex Taille Numérique\n`)
})

import '../css/index.scss'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

import Alpine from 'alpinejs'
import carousel from './carousel.js'
import iframe from './iframe.js'
import url from './url.js'
import listItem from './listItem.js'
import listDate from './listDate.js'
import listDatePast from './listDatePast.js'



document.addEventListener('alpine:init', () => {
  Alpine.data('carousel', carousel)
  Alpine.data('iframe', iframe)
  Alpine.data('listItem', listItem)
  Alpine.data('listDate', listDate)
  Alpine.data('listDatePast', listDatePast)
  Alpine.store('url', url)
})

window.Alpine = Alpine
window.Alpine.start()

