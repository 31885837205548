import queryStringUtil from 'query-string'

export default {
  params: {},
  init() {
    this.setParamsFromQueryString(window.location.search)
    window.onpopstate = (event) => {
      const params = event.state || {}
      this.set_params(params)
    }
  },
  hasParam(key) {
    return key in this.params
  },
  getParam(key, forceArray = false) {
    return this.hasParam(key) ? (forceArray && !Array.isArray(this.params[key]) ? [this.params[key]] : this.params[key]) : null
  },
  getQueryStringFromParams(params) {
    return queryStringUtil.stringify(params, { arrayFormat: 'comma', strict: false }).replace(/%20/g, '+')
  },
  getObjectFromQueryString(queryString) {
    return queryStringUtil.parse(queryString, { parseNumbers: true, parseBooleans: true, arrayFormat: 'comma' })
  },
  getUrl(queryString = '') {
    let baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
    return queryString.length ? baseUrl + '?' + queryString : baseUrl
  },
  getUrlFromParams(params) {
    let queryString = this.getQueryStringFromParams(params)
    return this.getUrl(queryString)
  },
  setParamsFromQueryString(queryString) {
    this.set_params(this.getObjectFromQueryString(queryString))
  },
  setParams(params) {
    this.set_params({ ...JSON.parse(JSON.stringify(this.params)), ...params })
    this.updateUrl(true)
  },
  replaceParams(params) {
    this.set_params({ ...JSON.parse(JSON.stringify(this.params)), ...params })
    this.updateUrl(false)
  },
  updateUrl(keepTrace = true) {
    if (keepTrace) {
      window.history.pushState(JSON.parse(JSON.stringify(this.params)), document.title, this.getUrlFromParams(this.params))
    } else {
      window.history.replaceState(JSON.parse(JSON.stringify(this.params)), document.title, this.getUrlFromParams(this.params))
    }
  },
  setUrl(url, title, keepTrace = true) {
    if (keepTrace) {
      window.history.pushState({}, title, url)
    } else {
      window.history.replaceState({}, title, url)
    }
  },
  set_params(payload) {
    Object.keys(payload).forEach((key) => {
      if ((payload[key] !== 0 && !payload[key]) || (Array.isArray(payload[key]) && !payload[key].length)) {
        delete payload[key]
      }
    })
    this.params = payload
  }
}
