export default () => ({
  items: [],
  get filteredDates() {
    return this.items.filter((item) => {
      let selected = true
     
      if (this.filter_category.length && !this.filter_category.every((category) => item.typeSlug.includes(String(category)))) selected = false
      if (this.filter_artist.length && !this.filter_artist.every((artist) => item.artistsIds.includes(String(artist)))) selected = false


      return selected

    })
  },
  


  get filter_artist() {
    return this.$store.url.getParam('artist') ? (Array.isArray(this.$store.url.getParam('artist')) ? this.$store.url.getParam('artist') : [this.$store.url.getParam('artist')]) : []
  },

 get filter_category() {
    return this.$store.url.getParam('category') ? (Array.isArray(this.$store.url.getParam('category')) ? this.$store.url.getParam('category') : [this.$store.url.getParam('category')]) : []
  },
  setDates(items = []) {
    this.items = [...items]
  }
})
